    .dive-map-wrapper {
        position: relative;
        min-height: 450px;
        width: 660px;
        overflow-x: hidden;
        @media (min-width: 660px) {
            width: 100%;
            padding-bottom: 67%;
            min-height: 450px;
        }
        .dive-map {
            position: absolute;
            min-width: 660px;
            min-height: 450px;
            background-size: 100% auto;
            /* or 100% */
            background-repeat: no-repeat;
            padding-bottom: 20%;
            /* Equal to width for 1:1 aspect ratio */
            @media (min-width: 660px) {
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                width: 100%;
            }
            .hero {
                position: absolute;
                left: 10px;
                top: -20px;
                @media (min-width: 660px) {
                    left: 30px;
                    top: 0px;
                }
                p {
                    display: none;
                    @media (min-width: 990px) {
                        display: block;
                    }
                }
            }
        }
        .plane {
            position: absolute;
            top: 80%;
            left: -100%;
            width: 83px;
            height: 76px;
            animation: flyPlane 25s linear infinite;
        }
        @keyframes flyPlane {
            0% {
                left: -10%;
                top: 90%
            }
            100% {
                left: 100%;
                top: 10%;
            }
        }
        .cloud1 {
            position: absolute;
            top: 55%;
            left: -20%;
            width: 61px;
            height: 33px;
            animation: flyCloud1 85s linear infinite;
        }
        @keyframes flyCloud1 {
            0% {
                left: -10%;
                top: 85%
            }
            100% {
                left: 110%;
                top: 20%;
            }
        }
        .cloud2 {
            position: absolute;
            top: 80%;
            left: -20%;
            width: 55px;
            height: 60px;
            animation: flyCloud2 55s linear infinite;
        }
        @keyframes flyCloud2 {
            0% {
                left: -10%;
                top: 95%
            }
            100% {
                left: 110%;
                top: -30%;
            }
        }
        .cloud3 {
            position: absolute;
            top: 30%;
            left: -20%;
            width: 83px;
            height: 76px;
            animation: flyCloud3 65s linear infinite;
        }
        @keyframes flyCloud3 {
            0% {
                left: -30%;
                top: 95%
            }
            100% {
                left: 110%;
                top: 0%;
            }
        }
    }