@import url('https://fonts.googleapis.com/css2?family=Syncopate:wght@400;700&display=swap');
h1,
h2,
h3,
h4,
h4,
.h1,
.h2,
.h3,
.h4,
.h4,
button,
header #main-menu a {
    font-family: 'Syncopate', sans-serif;
    font-weight: 700;
    span {
        font-weight: 400;
    }
}

.h1 {
    font-size: calc(1.375rem + 0.5vw);
    @media (min-width: 660px) {
        font-size: calc(1.375rem + 1.5vw);
    }
}

h2 {
    a {
        text-decoration: none;
        color: #d3540f;
    }
}

p.special_p,
header #main-menu h3,
div {
    font-family: 'Syncopate', sans-serif;
    font-weight: 400;
}

p {
    font-family: 'Trebuchet MS', sans-serif;
    a {
        text-decoration: none;
        color: #d3540f;
    }
}

h5 {
    a {
        text-decoration: none;
        color: #d3540f;
    }
}

.btn {
    border-radius: 0;
    font-weight: 700;
}

.btn-outline-dark {
    background-color: #fff
}

input.form-control,
textarea.form-control {
    border-radius: 0;
    padding-top: 20px;
    padding-bottom: 20px;
}

button.btn-close {
    font-size: 40px;
}