.social-roll {
    background-size: 100% 100%;
    height: 33px;
    width: 33px;
    margin: 5px;
    float: left;
    position: relative;
    -webkit-transition: all ease 0.3s;
    -moz-transition: all ease 0.3s;
    -o-transition: all ease 0.3s;
    -ms-transition: all ease 0.3s;
    transition: all ease 0.3s;
    filter: grayscale(100%);
    filter: gray;
    /* IE 6-9 */
    ;
}

.social-roll:hover {
    background-size: 100% 100%;
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
    -webkit-filter: grayscale(100%);
    filter: none;
    /* IE 6-9 */
    ;
}