@mixin animated_div_duplicate_properties {
    background-size: 100% auto;
    background-repeat: no-repeat;
    position: absolute;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

@mixin home {
    //background-image: url('./../img/bg.jpg');
    position: relative;
    display: block;
    overflow: hidden;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: center top;
    width: 100%;
    height: 0;
    padding-top: 161%;
    max-height: 3200px;
}