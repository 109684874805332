.accordion-button,
.accordion-body {
    background-color: #d8feff;
    &:not(.collapsed) {
        background-color: #d8feff;
        color: black;
    }
}

.accordion-button {
    cursor: pointer;
}