    @import './../css/includes.scss';
    @import './../css/animations.scss';
    .home {
        @include home;
        .lava {
            // background: url('./../img/lava.png');
            left: 64.2%;
            top: -0.3%;
            height: 5%;
            width: 13%;
            @include animated_div_duplicate_properties;
        }
        .start_here {
            // background: url('./../img/StartHere.png');
            left: 0%;
            top: 86px;
            height: 100px;
            width: 190px;
            padding-top: 0%;
            background-size: 100% auto;
            background-repeat: no-repeat;
            position: absolute;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            display: none;
            @media (min-width: 960px) {
                display: block;
            }
        }
        .lava_cloud {
            // background: url('./../img/lava_cloud.png');
            left: 29%;
            top: 0;
            height: 5%;
            width: 71%;
            padding-top: 0%;
            animation: MoveUpDown 1.2s linear infinite;
            @include animated_div_duplicate_properties;
        }
        .object {
            position: absolute;
            left: 0;
            bottom: 0;
        }
        .sun_fire {
            // background: url('./../img/sun_fire.png');
            left: 82.2%;
            top: 1.9%;
            height: 8%;
            width: 12%;
            transform-origin: 50% 50%;
            animation: spin 4s linear infinite;
            @include animated_div_duplicate_properties;
        }
        .sun_face {
            // background: url('./../img/sun_face.png');
            left: 84.9%;
            top: 3.9%;
            height: 8%;
            width: 6.5%;
            @include animated_div_duplicate_properties;
        }
        .ferry {
            // background: url('./../img/ferry.png');
            left: 4.9%;
            top: 8.7%;
            height: 4%;
            width: 19.5%;
            animation: MoveFerry 88s linear infinite;
            @include animated_div_duplicate_properties;
        }
        .boat {
            cursor: pointer;
            // background: url('./../img/boat.png');
            left: 14.9%;
            top: 6.2%;
            height: 33%;
            width: 66.1%;
            transform-origin: 50% 50%;
            animation: MoveBoat 2s linear infinite;
            @include animated_div_duplicate_properties;
            .teacher_arm {
                // background: url('./../img/teacher_arm.png');
                left: 39.15%;
                top: 7.56%;
                height: 10%;
                width: 2.5%;
                transform-origin: 0% 100%;
                animation: Waving .5s linear infinite;
                @include animated_div_duplicate_properties;
            }
            .gonza_arm {
                // background: url('./../img/gonza_arm.png');
                left: 59.22%;
                top: 2.2%;
                height: 12%;
                width: 5.5%;
                transform-origin: 0% 100%;
                animation: Waving .7s linear infinite;
                @include animated_div_duplicate_properties;
            }
        }
        .diver_arm {
            // background: url('./../img/diver_arm.png');
            left: 4.95%;
            top: 12.56%;
            height: 5%;
            width: 3.5%;
            transform-origin: 0% 100%;
            animation: Waving 1s linear infinite;
            @include animated_div_duplicate_properties;
        }
        .jellyfish {
            // background: url('./../img/jellyfish.png');
            left: 37%;
            top: 21.7%;
            height: 33%;
            width: 23%;
            animation: Jellyfish 33s linear infinite;
            @include animated_div_duplicate_properties;
        }
        .zebra_fish {
            // background: url('./../img/zebra_fish.png');
            left: 1.9%;
            top: 27.7%;
            height: 4%;
            width: 7%;
            animation: zebra_fish 20s linear infinite;
            @include animated_div_duplicate_properties;
        }
        .gold_fish {
            // background: url('./../img/gold_fish.png');
            left: 21.9%;
            top: 22.7%;
            height: 3%;
            width: 10%;
            animation: gold_fish 30s linear infinite;
            @include animated_div_duplicate_properties;
        }
        .blue_fish {
            // background: url('./../img/blue_fish.png');
            left: 21.9%;
            top: 35.7%;
            height: 3%;
            width: 10%;
            animation: blue_fish 40s linear infinite;
            @include animated_div_duplicate_properties;
        }
        .diver {
            // background: url('./../img/diver.png');
            left: 110%;
            top: 21.7%;
            height: 17%;
            width: 24%;
            animation: diver 34s linear infinite;
            @include animated_div_duplicate_properties;
        }
        .little_fish {
            // background: url('./../img/little_fish.png');
            left: -3%;
            top: 22.7%;
            height: 1.9%;
            width: 3%;
            animation: little_fish 40s linear infinite;
            @include animated_div_duplicate_properties;
        }
        .prawn {
            // background: url('./../img/prawn.png');
            left: 98.9%;
            top: 24.7%;
            height: 1.6%;
            width: 2%;
            animation: prawn 40s linear infinite;
            @include animated_div_duplicate_properties;
        }
        .barra1 {
            // background: url('./../img/barra1.png');
            left: -12%;
            top: 35%;
            height: 3.6%;
            width: 12%;
            animation: barra1 8s linear infinite;
            @include animated_div_duplicate_properties;
        }
        .barra2 {
            // background: url('./../img/barra2.png');
            left: -12%;
            top: 40%;
            height: 3.6%;
            width: 12%;
            animation: barra2 12s linear infinite;
            @include animated_div_duplicate_properties;
        }
        .barra3 {
            // background: url('./../img/barra3.png');
            left: -12%;
            top: 45%;
            height: 3.9%;
            width: 12%;
            animation: barra3 6s linear infinite;
            @include animated_div_duplicate_properties;
        }
        .barra4 {
            // background: url('./../img/barra4.png');
            left: -12%;
            top: 50%;
            height: 3.6%;
            width: 12%;
            animation: barra4 11s linear infinite;
            @include animated_div_duplicate_properties;
        }
        .barra5 {
            // background: url('./../img/barra5.png');
            left: -12%;
            top: 55%;
            height: 3.6%;
            width: 12%;
            animation: barra5 9s linear infinite;
            @include animated_div_duplicate_properties;
        }
        .barra5 {
            // background: url('./../img/barra5.png');
            left: -12%;
            top: 55%;
            height: 3.6%;
            width: 12%;
            animation: barra5 9s linear infinite;
            @include animated_div_duplicate_properties;
        }
        .barra6 {
            // background: url('./../img/barra6.png');
            left: -12%;
            top: 60%;
            height: 3.6%;
            width: 12%;
            animation: barra6 5s linear infinite;
            @include animated_div_duplicate_properties;
        }
        .turtle {
            // background: url('./../img/turtle.png');
            left: -24%;
            top: 37%;
            height: 11.6%;
            width: 23%;
            animation: turtle 55s linear infinite;
            @include animated_div_duplicate_properties;
        }
        .ray {
            // background: url('./../img/ray.png');
            left: 124%;
            top: 37%;
            height: 14.6%;
            width: 24%;
            animation: ray 88s linear infinite;
            @include animated_div_duplicate_properties;
        }
        .whale {
            // background: url('./../img/whale.png');
            left: 154%;
            top: 50%;
            height: 14%;
            width: 72%;
            animation: whale 79s linear infinite;
            @include animated_div_duplicate_properties;
        }
        .cliff_right {
            // background: url('./../img/cliff_right.png');
            left: 73.5%;
            top: 16.1%;
            height: 74%;
            width: 26.4%;
            @include animated_div_duplicate_properties;
        }
        .cliff_left {
            // background: url('./../img/cliff_left.png');
            left: 0%;
            top: 37%;
            height: 74%;
            width: 11.9%;
            @include animated_div_duplicate_properties;
        }
        .bask {
            // background: url('./../img/bask.png');
            left: 54%;
            top: 78%;
            height: 14%;
            width: 27%;
            animation: bask 48s linear infinite;
            @include animated_div_duplicate_properties;
        }
        .monkey_arm_1 {
            // background: url('./../img/monkey_arm_1.png');
            left: 47.5%;
            top: 87.5%;
            height: 14%;
            width: 9%;
            transform-origin: 100% 0%;
            animation: monkey_arm_1 .25s alternate infinite;
            @include animated_div_duplicate_properties;
        }
        .monkey_arm_2 {
            // background: url('./../img/monkey_arm_2.png');
            left: 46.5%;
            top: 87%;
            height: 14%;
            width: 10%;
            transform-origin: 100% 0%;
            animation: monkey_arm_2 .4s alternate infinite;
            @include animated_div_duplicate_properties;
        }
        .blink {
            cursor: pointer;
            background: url('./../img/blink.png');
            left: 16.8%;
            top: 72%;
            height: 18%;
            width: 23.5%;
            animation: blink .5s alternate infinite;
            @include animated_div_duplicate_properties;
            &:hover {
                filter: invert(100%);
            }
        }
        .eel {
            // background: url('./../img/eel.png');
            left: 3%;
            top: 61.7%;
            height: 14%;
            width: 33%;
            animation: eel 4s alternate infinite;
            @include animated_div_duplicate_properties;
            .eel_mouth {
                // background: url('./../img/eel_mouth.png');
                left: 87%;
                top: 27.5%;
                height: 14%;
                width: 11%;
                transform-origin: 0% 50%;
                animation: eel_mouth .6s alternate infinite;
                @include animated_div_duplicate_properties;
            }
        }
        .dolphin_contain {
            background-size: 100% auto;
            left: 3%;
            top: 47.7%;
            height: 6.6%;
            width: 24%;
            animation: dolphin_contain 15s linear infinite;
            @include animated_div_duplicate_properties;
            .dolphin {
                // background: url('./../img/dolphin.png') 0 0 no-repeat;
                left: 0%;
                top: 0%;
                height: 100%;
                width: 100%;
                animation: dolphin .9s steps(4) infinite;
                @include animated_div_duplicate_properties;
            }
        }
        .App_city_sparkles {
            width: 38%;
            height: 20%;
            position: absolute;
            top: 50%;
            left: 40%;
            .App_sparkle {
                width: 4px;
                height: 4px;
                position: relative;
                border-radius: 50%;
                background-color: cyan;
                box-shadow: 1px 5px 40px 10px rgba(3, 223, 255, 0.36);
            }
            .s1 {
                top: 30%;
                left: 60%;
                animation: blink-animation 3s infinite;
                animation-fill-mode: forwards;
                -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
            }
            .s2 {
                top: 40%;
                left: 40%;
                animation: blink-animation 6s infinite;
                animation-fill-mode: forwards;
                -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
            }
            .s3 {
                top: 50%;
                left: 57%;
                animation: blink-animation 1s infinite;
                animation-fill-mode: forwards;
                -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
            }
            .s4 {
                top: 60%;
                left: 65%;
                animation: blink-animation 11s infinite;
                animation-fill-mode: forwards;
                -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
            }
            .s5 {
                top: 70%;
                left: 63%;
                animation: blink-animation 9s infinite;
                animation-fill-mode: forwards;
                -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
            }
            .s6 {
                top: 89%;
                left: 62%;
                animation: blink-animation 12s infinite;
                animation-fill-mode: forwards;
                -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
            }
            .s7 {
                top: 33%;
                left: 44%;
                animation: blink-animation 8s infinite;
                animation-fill-mode: forwards;
                -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
            }
            .s8 {
                top: 6%;
                left: 88%;
                animation: blink-animation 12s infinite;
                animation-fill-mode: forwards;
                -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
            }
            .s9 {
                top: 59%;
                left: 32%;
                animation: blink-animation 22s infinite;
                animation-fill-mode: forwards;
                -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
            }
            .s10 {
                top: 12%;
                left: 50%;
                animation: blink-animation 1s infinite;
                animation-fill-mode: forwards;
                -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
            }
        }
        .App_bubbles {
            bottom: 0;
            left: 0;
            position: absolute;
            right: 0;
            top: 19.4%;
            overflow: hidden;
            z-index: 0;
            display: none;
            @media (min-width: 660px) {
                display: block;
            }
            .x1 {
                animation: animateBubble 50s linear infinite, sideWays 2s ease-in-out infinite alternate;
                left: 2%;
                top: 105%;
                transform: scale(1.6);
            }
            .x2 {
                animation: animateBubble 40s linear infinite, sideWays 4s ease-in-out infinite alternate;
                left: 5%;
                top: 55%;
                transform: scale(1.4);
            }
            .x3 {
                animation: animateBubble 76s linear infinite, sideWays 2s ease-in-out infinite alternate;
                left: 10%;
                top: 75%;
                transform: scale(1.7);
            }
            .x4 {
                animation: animateBubble 44s linear infinite, sideWays 3s ease-in-out infinite alternate;
                left: 14%;
                top: 95%;
                transform: scale(1.3);
            }
            .x5 {
                animation: animateBubble 58s linear infinite, sideWays 4s ease-in-out infinite alternate;
                left: 20%;
                top: 105%;
                transform: scale(1.5);
            }
            .x6 {
                animation: animateBubble 42s linear infinite, sideWays 2s ease-in-out infinite alternate;
                left: 80%;
                top: 125%;
                transform: scale(1.8);
            }
            .x7 {
                animation: animateBubble 40s linear infinite, sideWays 2s ease-in-out infinite alternate;
                left: 85%;
                top: 112%;
                transform: scale(1.4);
            }
            .x8 {
                animation: animateBubble 44s linear infinite, sideWays 3s ease-in-out infinite alternate;
                left: 82%;
                top: 145%;
                transform: scale(1.3);
            }
            .x9 {
                animation: animateBubble 58s linear infinite, sideWays 4s ease-in-out infinite alternate;
                left: 92%;
                top: 135%;
                transform: scale(1.6);
            }
            .x10 {
                animation: animateBubble 52s linear infinite, sideWays 2s ease-in-out infinite alternate;
                left: 96%;
                top: 75%;
                transform: scale(1.3);
            }
            .x11 {
                animation: animateBubble 60s linear infinite, sideWays 2s ease-in-out infinite alternate;
                left: 12%;
                top: 101%;
                transform: scale(1.6);
            }
            .x12 {
                animation: animateBubble 44s linear infinite, sideWays 4s ease-in-out infinite alternate;
                left: 7%;
                top: 59%;
                transform: scale(1.4);
            }
            .x13 {
                animation: animateBubble 52s linear infinite, sideWays 2s ease-in-out infinite alternate;
                left: 2%;
                top: 87%;
                transform: scale(1.7);
            }
            .x14 {
                animation: animateBubble 48s linear infinite, sideWays 3s ease-in-out infinite alternate;
                left: 12%;
                top: 125%;
                transform: scale(1.3);
            }
            .x15 {
                animation: animateBubble 64s linear infinite, sideWays 4s ease-in-out infinite alternate;
                left: 22%;
                top: 11%;
                transform: scale(1.5);
            }
            .x16 {
                animation: animateBubble 49s linear infinite, sideWays 2s ease-in-out infinite alternate;
                left: 81%;
                top: 28%;
                transform: scale(1.8);
            }
            .x17 {
                animation: animateBubble 48s linear infinite, sideWays 2s ease-in-out infinite alternate;
                left: 87%;
                top: 37%;
                transform: scale(1.4);
            }
            .x18 {
                animation: animateBubble 42s linear infinite, sideWays 3s ease-in-out infinite alternate;
                left: 84%;
                top: -10%;
                transform: scale(1.3);
            }
            .x19 {
                animation: animateBubble 53s linear infinite, sideWays 4s ease-in-out infinite alternate;
                left: 82%;
                top: -20%;
                transform: scale(1.6);
            }
            .x20 {
                animation: animateBubble 59s linear infinite, sideWays 2s ease-in-out infinite alternate;
                left: 91%;
                top: -30%;
                transform: scale(1.3);
            }
            .x21 {
                animation: animateBubble 30s linear infinite, sideWays 2s ease-in-out infinite alternate;
                left: 7%;
                top: 87%;
                transform: scale(1.6);
            }
            .x22 {
                animation: animateBubble 35s linear infinite, sideWays 4s ease-in-out infinite alternate;
                left: 2%;
                top: 99%;
                transform: scale(1.4);
            }
            .x23 {
                animation: animateBubble 71s linear infinite, sideWays 2s ease-in-out infinite alternate;
                left: 4%;
                top: 78%;
                transform: scale(1.7);
            }
            .x24 {
                animation: animateBubble 85s linear infinite, sideWays 3s ease-in-out infinite alternate;
                left: 14%;
                top: 94%;
                transform: scale(0.3);
            }
            .x25 {
                animation: animateBubble 84s linear infinite, sideWays 4s ease-in-out infinite alternate;
                left: 12%;
                top: 85%;
                transform: scale(0.5);
            }
            .x26 {
                animation: animateBubble 39s linear infinite, sideWays 2s ease-in-out infinite alternate;
                left: 83%;
                top: 121%;
                transform: scale(0.8);
            }
            .x27 {
                animation: animateBubble 46s linear infinite, sideWays 2s ease-in-out infinite alternate;
                left: 88%;
                top: -50%;
                transform: scale(0.4);
            }
            .x28 {
                animation: animateBubble 88s linear infinite, sideWays 3s ease-in-out infinite alternate;
                left: 86%;
                top: 142%;
                transform: scale(0.3);
            }
            .x29 {
                animation: animateBubble 102s linear infinite, sideWays 4s ease-in-out infinite alternate;
                left: 95%;
                top: 137%;
                transform: scale(0.6);
            }
            .x30 {
                animation: animateBubble 111s linear infinite, sideWays 2s ease-in-out infinite alternate;
                left: 79%;
                top: 79%;
                transform: scale(0.3);
            }
            .x31 {
                animation: animateBubble 80s linear infinite, sideWays 2s ease-in-out infinite alternate;
                left: 18%;
                top: 104%;
                transform: scale(0.6);
            }
            .x32 {
                animation: animateBubble 54s linear infinite, sideWays 4s ease-in-out infinite alternate;
                left: 17%;
                top: 32%;
                transform: scale(0.4);
            }
            .x33 {
                animation: animateBubble 59s linear infinite, sideWays 2s ease-in-out infinite alternate;
                left: 3%;
                top: 40%;
                transform: scale(0.7);
            }
            .x34 {
                animation: animateBubble 45s linear infinite, sideWays 3s ease-in-out infinite alternate;
                left: 1%;
                top: 123%;
                transform: scale(0.3);
            }
            .x35 {
                animation: animateBubble 76s linear infinite, sideWays 4s ease-in-out infinite alternate;
                left: 14%;
                top: 33%;
                transform: scale(0.5);
            }
            .x36 {
                animation: animateBubble 74s linear infinite, sideWays 2s ease-in-out infinite alternate;
                left: 18%;
                top: 18%;
                transform: scale(0.8);
            }
            .x37 {
                animation: animateBubble 93s linear infinite, sideWays 2s ease-in-out infinite alternate;
                left: 75%;
                top: 55%;
                transform: scale(0.4);
            }
            .x38 {
                animation: animateBubble 98s linear infinite, sideWays 3s ease-in-out infinite alternate;
                left: 24%;
                top: -100%;
                transform: scale(0.3);
            }
            .x39 {
                animation: animateBubble 50s linear infinite, sideWays 4s ease-in-out infinite alternate;
                left: 22%;
                top: 102%;
                transform: scale(0.6);
            }
            .x40 {
                animation: animateBubble 52s linear infinite, sideWays 2s ease-in-out infinite alternate;
                left: 61%;
                top: 130%;
                transform: scale(0.3);
            }
            .bubble {
                opacity: 0.6;
                background-image: url(./../img/bubble.svg);
                height: 1%;
                position: absolute;
                width: 1%;
                background-size: 100% auto;
                background-position: center top;
                background-repeat: no-repeat;
            }
        }
        .ArchibaldButler {
            cursor: pointer;
            background: url('./../img/ArchbaldButler.png') 0 0 no-repeat;
            background-size: 100% auto;
            top: 93.6%;
            height: 6.6%;
            width: 11%;
            @include animated_div_duplicate_properties;
            &:hover {
                background: url('./../img/ArchibaldButler_hover.png') 0 0 no-repeat;
                @include animated_div_duplicate_properties;
            }
        }
    }