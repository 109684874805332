.marker_wrap {
    position: absolute;
    left: 37%;
    top: 79.6%;
    .logo {
        background-image: url(./../img/scuba-point-pin.svg);
        background-repeat: no-repeat;
        width: 100px;
        height: 75px;
        position: absolute;
        top: -56px;
        left: -40px;
    }
    .marker {
        transform: perspective(40px) rotateX(20deg) rotateZ(-45deg);
        transform-origin: 50% 50%;
        border-radius: 50% 50% 50% 0;
        padding: 0 3px 3px 0;
        width: 60px;
        height: 60px;
        position: relative;
        top: -24px;
        left: -9px;
        background: #d8feff;
        margin: -2.2em 0 0 -1.3em;
        -webkit-box-shadow: -1px 1px 4px rgba(0, 0, 0, .5);
        -moz-box-shadow: -1px 1px 4px rgba(0, 0, 0, .5);
        box-shadow: -1px 1px 4px rgba(0, 0, 0, .5);
    }
    .marker:after {
        content: '';
        width: 1em;
        height: 1em;
        margin: 1em 0 0 .7em;
        position: absolute;
        border-radius: 50%;
    }
}

.dive-spot-1 {
    left: 31%;
    top: 80%;
}

.dive-spot-2 {
    left: 32%;
    top: 86%;
}

.dive-spot-3 {
    left: 35%;
    top: 83%;
}

.dive-spot-4 {
    left: 36%;
    top: 88%;
}

.dive-spot-5 {
    left: 40%;
    top: 84%;
}

.dive-spot-6 {
    left: 40%;
    top: 90%;
}

.dive-spot-7 {
    left: 45%;
    top: 82%;
}

.dive-spot-8 {
    left: 45%;
    top: 88%;
}

.dive-spot-9 {
    left: 52%;
    top: 78%;
}

.dive-spot-10 {
    left: 51%;
    top: 86%;
}

.dive-spot-11 {
    left: 55%;
    top: 83%;
}

.dive-spot-12 {
    left: 62%;
    top: 65%;
}

.dive-spot-13 {
    left: 64%;
    top: 61%;
}

.dive-spot-14 {
    left: 60%;
    top: 57%;
}

.dive-spot-15 {
    left: 61%;
    top: 52%;
}

.dive-spot-16 {
    left: 64%;
    top: 46%;
}

.dive-spot-17 {
    left: 64%;
    top: 41%;
}

.dive-spot-18 {
    left: 46%;
    top: 28%;
}

.dive-spot-19 {
    left: 31%;
    top: 33%;
}

.dive-spot-20 {
    left: 24%;
    top: 54%;
}

.dive-spot-21 {
    left: 27%;
    top: 64%;
}

.dive-spot-22 {
    left: 29%;
    top: 69%;
}

.dive-spot-23 {
    left: 5%;
    top: 71%;
}

.dive-spot-1,
.dive-spot-2,
.dive-spot-3,
.dive-spot-4,
.dive-spot-5,
.dive-spot-6,
.dive-spot-7,
.dive-spot-8,
.dive-spot-9,
.dive-spot-10,
.dive-spot-11,
.dive-spot-12,
.dive-spot-13,
.dive-spot-14,
.dive-spot-15,
.dive-spot-16,
.dive-spot-17,
.dive-spot-18,
.dive-spot-19,
.dive-spot-20,
.dive-spot-21,
.dive-spot-22,
.dive-spot-23 {
    font-size: 16px;
    position: absolute;
    width: 5%;
    height: 5%;
    color: black;
    cursor: pointer;
    animation: pulse 2s infinite;
    transform-origin: center;
    @keyframes pulse {
        0% {
            transform-origin: center;
            transform: scale(1);
            opacity: 1;
        }
        50% {
            transform-origin: center;
            transform: scale(1.2);
            opacity: 0.9;
        }
        100% {
            transform-origin: center;
            transform: scale(1);
            opacity: 1;
        }
    }
    &:hover {
        opacity: 0.7;
    }
    &:before {
        content: "";
        display: block;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        left: 5px;
        top: 8px;
        position: absolute;
        background-color: white;
        border: 2px solid black;
    }
    span {
        position: absolute;
        top: 12px;
        left: 10px;
        text-align: center;
        width: 20px;
        height: 20px;
    }
}

.dive-spot-20,
.dive-spot-21,
.dive-spot-22,
.dive-spot-23 {
    font-size: 14px;
}

.towns {
    h5 {
        position: absolute;
        height: 18px;
        font-size: 8px;
        background-color: white;
        border: 1px solid black;
        color: black;
        padding: 5px;
    }
    h5:nth-child(1) {
        left: 37%;
        top: 81%;
    }
    h5:nth-child(2) {
        left: 26%;
        top: 47%;
    }
    h5:nth-child(3) {
        left: 70%;
        top: 23%;
    }
    h5:nth-child(4) {
        left: 66%;
        top: 18%;
    }
    h5:nth-child(5) {
        left: 67%;
        top: 10%;
    }
    h5:nth-child(6) {
        left: 51%;
        top: 32%;
    }
    h5:nth-child(7) {
        left: 49%;
        top: 78%;
    }
    h5:nth-child(8) {
        left: 59%;
        top: 65%;
    }
    h5:nth-child(9) {
        left: 42%;
        top: 49%;
    }
}