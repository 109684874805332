@import './../css/fonts.scss';

header {
    position: absolute;
    top: 0;
    left: 0;

    .nav_booking {
        position: absolute;
        top: 6px;
        left: 170px;

        @media (min-width: 660px) {
            top: 30px;
            left: 222px;
        }

        button {
            font-size: 16px;

            @media (min-width: 660px) {
                font-size: 18px;
            }
        }
    }

    img.logo {
        width: 96px;
        height: auto;
        position: absolute;
        top: 9px;
        left: 58px;
        z-index: 0;
        display: block;

        &:hover {
            opacity: 0.7;
        }

        @media (min-width: 660px) {
            width: 130px;
            top: 25px;
            left: 78px;
        }
    }

    // animation 
    @keyframes show-left {
        0% {
            transform: translate(-210px, 0);
        }

        100% {
            transform: translate(0, 0);
        }
    }

    @keyframes retract-left {
        0% {
            transform: translate(0, 0);
        }

        100% {
            transform: translate(-210px, 0);
        }
    }

    // toggleon to viewport
    #main-menu.menu-on {
        animation: show-left .3s forwards;
        position: fixed;
    }

    #main-menu.menu-off {
        animation: retract-left .3s forwards;
        position: relative;
    }

    .menu-button {
        top: 4px;
        left: 10px;
        position: absolute;
        width: 42px;
        height: 50px;
        cursor: pointer;
        background: transparent;
        border: 0;
        padding: 0;
        margin: 0;
        text-shadow: none;
        outline: 0;

        @media (min-width: 660px) {
            top: 30px;
            left: 30px;
        }

        &:active {
            outline: 0;
        }

        .burger-menu {
            position: relative;
            width: 43px;
            height: 50px;
            top: 0px;
            left: 0px;
            cursor: pointer;
        }

        .burger {
            position: absolute;
            background: white;
            border: 1px solid black;
            width: 43px;
            height: 9px;
            top: 50%;
            right: 0px;
            margin-top: -3px;
            opacity: 1;
        }

        .burger::before {
            position: absolute;
            background: white;
            border: 1px solid black;
            width: 43px;
            height: 9px;
            left: -1px;
            top: 15px;
            content: "";
            display: block;
        }

        .burger::after {
            position: absolute;
            background: white;
            border: 1px solid black;
            width: 43px;
            left: -1px;
            height: 9px;
            bottom: 16px;
            content: "";
            display: block;
        }

        .burger::after,
        .burger::before,
        .burger {
            transition: all .3s ease-in-out;
            -webkit-transition: all .3s ease-in-out;
        }

        .burger-menu.show-cross {
            position: relative;
            top: 6px;
            left: 0px;

            @media (min-width: 660px) {
                top: 6px;
                left: 0px;
            }

            img.logo {
                // filter: invert(100%);
                top: 2px;

                @media (min-width: 660px) {
                    top: -6px;
                }
            }
        }

        .show-cross .burger::after {
            transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
            bottom: 4px;
            background: black;
        }

        .show-cross .burger::before {
            transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            top: -5px;
            background: black;
        }

        .show-cross .burger {
            border: #d8feff;
            background: #d8feff;
        }

        &:hover {
            outline: 0;
            opacity: 0.7;
        }
    }

    #main-menu {
        display: none;
        position: relative;
        width: 210px;
        height: 100vh;
        top: 0;
        left: 0px;
        background-color: #d8feff;
        border-right: 1px solid black;

        @media (min-width: 660px) {
            width: 260px;
        }

        .social-roll-nav-wrap {
            height: 46px;
            width: 168px;
            //        width: 90px; for 2
            position: relative;
            margin: 0 auto 20px auto;
            display: block;
            margin: 28px;

            @media (min-width: 660px) {
                width: 210px;
            }
        }

        h3 {
            padding: 108px 20px 20px 32px;
            margin: 0;
            font-weight: 700;
            font-size: 12px;
            letter-spacing: 1px;
            text-align: left;
            border-bottom: 1px solid black;

            @media (min-width: 660px) {
                font-size: 18px;
            }
        }

        a.main_nav {
            position: relative;
            clear: both;
            display: block;
            text-align: left;
            border-bottom: 1px solid black;
            padding: 10px 10px 10px 32px;
            display: block;
            font-weight: 700;
            // font-size: 18px;
            font-size: 16px;
            letter-spacing: 0px;
            text-decoration: none;
            color: black;

            @media (min-width: 660px) {
                font-size: 21px;
            }

            &:hover {
                background: black;
                color: white;
            }

            &:focus,
            &:active {
                border-bottom: 1px solid black;
            }

            &.buy_now {
                &::after {
                    content: "BUY HERE!";
                    position: absolute;
                    font-size: 10px;
                    color: #d3540f;
                    left: 50px;
                    top: 28px;
                    animation: pulse 2s infinite;

                    @media (min-width: 660px) {
                        left: 70px;
                        top: 33px;
                    }
                }
            }
        }
    }
}

// burger