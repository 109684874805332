.polaroid {
    background: #fff;
    padding: 1rem;
    box-shadow: 0 0.2rem 1.2rem rgba(0, 0, 0, 0.2);
}

.polaroid>img {
    max-width: 100%;
    height: auto;
    cursor: pointer
}

.item .polaroid:before {
    content: '';
    position: absolute;
    z-index: -1;
    transition: all 0.35s;
}

.item:nth-of-type(4n+1) {
    transform: scale(0.8, 0.8) rotate(5deg);
    transition: all 0.35s;
}

.item:nth-of-type(4n+1) .polaroid:before {
    transform: rotate(6deg);
    height: 20%;
    width: 47%;
    bottom: 30px;
    right: 12px;
    box-shadow: 0 2.1rem 2rem rgba(0, 0, 0, 0.4);
}

.item:nth-of-type(4n+2) {
    transform: scale(0.8, 0.8) rotate(-5deg);
    transition: all 0.35s;
}

.item:nth-of-type(4n+2) .polaroid:before {
    transform: rotate(-6deg);
    height: 20%;
    width: 47%;
    bottom: 30px;
    left: 12px;
    box-shadow: 0 2.1rem 2rem rgba(0, 0, 0, 0.4);
}

.item:nth-of-type(4n+4) {
    transform: scale(0.8, 0.8) rotate(3deg);
    transition: all 0.35s;
}

.item:nth-of-type(4n+4) .polaroid:before {
    transform: rotate(4deg);
    height: 20%;
    width: 47%;
    bottom: 30px;
    right: 12px;
    box-shadow: 0 2.1rem 2rem rgba(0, 0, 0, 0.3);
}

.item:nth-of-type(4n+3) {
    transform: scale(0.8, 0.8) rotate(-3deg);
    transition: all 0.35s;
}

.item:nth-of-type(4n+3) .polaroid:before {
    transform: rotate(-4deg);
    height: 20%;
    width: 47%;
    bottom: 30px;
    left: 12px;
    box-shadow: 0 2.1rem 2rem rgba(0, 0, 0, 0.3);
}

.item:hover {
    filter: none;
    transform: scale(1, 1) rotate(0deg) !important;
    transition: all 0.35s;
}

.item:hover .polaroid:before {
    content: '';
    position: absolute;
    z-index: -1;
    transform: rotate(0deg);
    height: 90%;
    width: 90%;
    bottom: 0%;
    right: 5%;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.2);
    transition: all 0.35s;
}