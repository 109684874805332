body .CookieConsent {
    position: fixed !important;
    display: block !important;
    background-color: rgba(0, 0, 0, 0.5) !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    z-index: 1 !important;
    button#rcc-confirm-button {
        display: block !important;
        position: relative !important;
        margin: 0 auto !important;
        width: 100% !important;
        top: 85px !important;
        padding: 10px !important;
        @media (min-width: 660px) {
            width: 49.2% !important;
        }
    }
    .inner_cookie {
        background-color: #FFF;
        color: black;
        display: block;
        position: relative;
        margin: 0 auto;
        width: 100%;
        top: 100px;
        padding: 30px;
        @media (min-width: 660px) {
            width: 50%;
        }
    }
    // &::before {
    //     content: '';
    //     position: fixed;
    //     display: block;
    //     top: 0;
    //     left: 0;
    //     bottom: 0;
    //     right: 0;
    //     background-color: rgba(0, 0, 0, 0.5);
    // }
}