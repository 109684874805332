.loading {
    width: 100%;
    max-width: 200px;
    /* real image width */
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}