footer {
    position: relative;
    // border-top: 1px solid black;
    a {
        cursor: pointer;
    }
    .logo {
        margin: 20px auto 20px auto;
        width: 120px;
        height: auto;
        position: relative;
        z-index: -1;
        display: block;
        &:hover {
            opacity: 0.7;
        }
        @media (min-width: 460px) {
            margin: 0 10px 20px 10px;
            float: left;
        }
        @media (min-width: 660px) {
            width: 130px;
            margin: 30px;
        }
    }
    .social-roll-wrap {
        height: 46px;
        width: 259px;
        //        width: 90px; for 2
        position: relative;
        margin: 0 auto 20px auto;
        display: block;
        @media (min-width: 460px) {
            float: right;
        }
        @media (min-width: 660px) {
            margin: 36px;
        }
    }
}