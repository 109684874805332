@import 'fonts.scss';
body {
    background-color: #d8feff;
}

.wrapper {
    margin-top: 100px;
    margin-bottom: 100px;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    right: 0;
    bottom: 0;
    @media (min-width: 660px) {
        margin-top: 160px;
        margin-bottom: 150px;
    }
}